import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "./UsersScreen.css";
import Loader from "./Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faBriefcase, faBirthdayCake, faTrashAlt, faEdit, faMoneyBillAlt, faSearch, faCalendar, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "./ConfirmationModal";
import userIcon from "./Assets/user-icon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UsersScreen = () => {
  const [usersByDate, setUsersByDate] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalState, setModalState] = useState({ show: false, user: null });
  const [editModalState, setEditModalState] = useState({ show: false, user: null });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsersByDate, setFilteredUsersByDate] = useState({});

  // Group users by date
  const groupUsersByDate = (users) => {
    return users.reduce((groups, user) => {
      const date = user.createdAt ? user.createdAt.toDate().toLocaleDateString() : 'No Date';
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(user);
      return groups;
    }, {});
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await firebase.firestore().collection("users").get();
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort users by 'createdAt' field in descending order (new users first)
        const sortedUsers = usersData.sort((a, b) => {
          const aCreatedAt = a.createdAt ? a.createdAt.toMillis() : 0;
          const bCreatedAt = b.createdAt ? b.createdAt.toMillis() : 0;
          return bCreatedAt - aCreatedAt;
        });

        const groupedUsers = groupUsersByDate(sortedUsers);
        setUsersByDate(groupedUsers);
        setFilteredUsersByDate(groupedUsers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users: ", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filteredUsers = {};
    Object.keys(usersByDate).forEach((date) => {
      const users = usersByDate[date].filter((user) =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.phoneNumber?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      if (users.length) {
        filteredUsers[date] = users;
      }
    });
    setFilteredUsersByDate(filteredUsers);
  }, [searchTerm, usersByDate]);

  const handleDelete = (user) => {
    setModalState({ show: true, user });
  };

  const confirmDelete = async () => {
    try {
      await firebase.firestore().collection("users").doc(modalState.user.id).delete();
      setUsersByDate((prev) => {
        const newUsersByDate = { ...prev };
        Object.keys(newUsersByDate).forEach((date) => {
          newUsersByDate[date] = newUsersByDate[date].filter((item) => item.id !== modalState.user.id);
          if (!newUsersByDate[date].length) {
            delete newUsersByDate[date]; // Remove the date key if no users are left
          }
        });
        return newUsersByDate;
      });
      setModalState({ show: false, user: null });
    } catch (error) {
      console.error("Error deleting user: ", error);
    }
  };

  const closeModal = () => {
    setModalState({ show: false, user: null });
  };

  const handleEdit = (user) => {
    setEditModalState({ show: true, user });
  };

  const closeEditModal = () => {
    setEditModalState({ show: false, user: null });
  };


//   const handleEditSubmit = async (updatedUser) => {
//     try {
//         // Convert balance to a number before submitting
//         updatedUser.balance = parseFloat(updatedUser.balance);

//         // Fetch existing user balance to calculate the added balance
//         const userDoc = await firebase.firestore().collection("users").doc(updatedUser.id).get();
//         const previousBalance = userDoc.data()?.balance || 0;
//         const addedBalance = updatedUser.balance - previousBalance;

//         // Check if isSubscribed is false and remove fields if necessary
//         if (!updatedUser.isSubscribed) {
//             delete updatedUser.isSubscribed;
//             delete updatedUser.subscriptionStartDate;
//             delete updatedUser.subscriptionEndDate;

//             await firebase.firestore().collection("users").doc(updatedUser.id).update({
//                 ...updatedUser,
//                 isSubscribed: firebase.firestore.FieldValue.delete(),
//                 subscriptionStartDate: firebase.firestore.FieldValue.delete(),
//                 subscriptionEndDate: firebase.firestore.FieldValue.delete(),
//             });
//         } else {
//             await firebase.firestore().collection("users").doc(updatedUser.id).update(updatedUser);
//         }

//         // If balance is added, save it in the AddedBalances and transactions collections
//         if (addedBalance > 0) {
//             const transactionData = {
//               amount: `${addedBalance} (From Lexdoo)`,
//               email: updatedUser.email || "Not provided",
//               name: updatedUser.name || "Not provided",
//               phoneNumber: updatedUser.phoneNumber || "Not provided",
//               status: "SUCCESS",
//               timestamp: firebase.firestore.FieldValue.serverTimestamp(),
//               type: "credit", // Set type as lexdoo
//               transactionType: "added_balance",
//               userId: updatedUser.id,
//             };

//             try {
//                 // Add to AddedBalances collection
//                 await firebase.firestore().collection("AddedBalances").add({
//                     name: updatedUser.name,
//                     email: updatedUser.email,
//                     phoneNumber: updatedUser.phoneNumber,
//                     addedCoins: addedBalance,
//                     previousBalance: previousBalance, // Include previous balance
//                     updatedBalance: updatedUser.balance, // Include updated balance
//                     balance: updatedUser.balance,
//                     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
//                 });

//                 // Add to transactions collection
//                 await firebase.firestore().collection("transactions").add(transactionData);

//                 console.log("Added balance and transaction record created successfully.");
//             } catch (error) {
//                 console.error("Error creating AddedBalances or transaction record:", error);
//             }
//         }

//         // Update local state
//         setUsersByDate((prev) => {
//             const newUsersByDate = { ...prev };
//             Object.keys(newUsersByDate).forEach((date) => {
//                 newUsersByDate[date] = newUsersByDate[date].map((user) =>
//                     user.id === updatedUser.id ? updatedUser : user
//                 );
//             });
//             return newUsersByDate;
//         });
//         setEditModalState({ show: false, user: null });
//     } catch (error) {
//         console.error("Error updating user:", error);
//     }
// };



const handleEditSubmit = async (updatedUser) => {
  try {
      // Convert balance to a number before submitting
      updatedUser.balance = parseFloat(updatedUser.balance);

      // Fetch existing user balance to calculate the change
      const userDoc = await firebase.firestore().collection("users").doc(updatedUser.id).get();
      const previousBalance = userDoc.data()?.balance || 0;
      const balanceChange = updatedUser.balance - previousBalance;

      // Check if isSubscribed is false and remove fields if necessary
      if (!updatedUser.isSubscribed) {
          delete updatedUser.isSubscribed;
          delete updatedUser.subscriptionStartDate;
          delete updatedUser.subscriptionEndDate;

          await firebase.firestore().collection("users").doc(updatedUser.id).update({
              ...updatedUser,
              isSubscribed: firebase.firestore.FieldValue.delete(),
              subscriptionStartDate: firebase.firestore.FieldValue.delete(),
              subscriptionEndDate: firebase.firestore.FieldValue.delete(),
          });
      } else {
          await firebase.firestore().collection("users").doc(updatedUser.id).update(updatedUser);
      }

      // If balance is changed, save it in the AddedBalances and transactions collections
      if (balanceChange !== 0) {
          const isCredit = balanceChange > 0; // Positive: credit, Negative: debit
          const absoluteChange = Math.abs(balanceChange); // Always store absolute value for clarity

          const transactionData = {
              amount: `${absoluteChange} (From Lexdoo)`,
              email: updatedUser.email || "Not provided",
              name: updatedUser.name || "Not provided",
              phoneNumber: updatedUser.phoneNumber || "Not provided",
              status: "SUCCESS",
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              type: isCredit ? "credit" : "debit", // Set type based on balance change
              transactionType: "balance_update",
              userId: updatedUser.id,
          };

          try {
              // Add to AddedBalances collection
              await firebase.firestore().collection("AddedBalances").add({
                  name: updatedUser.name,
                  email: updatedUser.email,
                  phoneNumber: updatedUser.phoneNumber,
                  balanceChange: isCredit ? absoluteChange : -absoluteChange, // Positive for credit, negative for debit
                  changeType: isCredit ? "credit" : "debit", // Explicitly specify the type
                  previousBalance: previousBalance, // Include previous balance
                  updatedBalance: updatedUser.balance, // Include updated balance
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              });

              // Add to transactions collection
              await firebase.firestore().collection("transactions").add(transactionData);

              console.log("Balance update and transaction record created successfully.");
          } catch (error) {
              console.error("Error creating AddedBalances or transaction record:", error);
          }
      }

      // Update local state
      setUsersByDate((prev) => {
          const newUsersByDate = { ...prev };
          Object.keys(newUsersByDate).forEach((date) => {
              newUsersByDate[date] = newUsersByDate[date].map((user) =>
                  user.id === updatedUser.id ? updatedUser : user
              );
          });
          return newUsersByDate;
      });
      setEditModalState({ show: false, user: null });
  } catch (error) {
      console.error("Error updating user:", error);
  }
};


  
  
  

  if (loading) {
    return (
      <div className="loading">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="header">
        <div className="user-count">
          <p>Total Users: {Object.keys(filteredUsersByDate).reduce((total, date) => total + filteredUsersByDate[date].length, 0)}</p>
        </div>
        <div className="search-container">
          <div className="search">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              className="search-box"
              placeholder="Search Users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="users-screen">
      <div className="users-container">
  {Object.keys(filteredUsersByDate).map((date) => (
    <div key={date} className="date-group">
      {/* Display the date with the count of users for that date */}
      <h3>{date} - {filteredUsersByDate[date].length} Users</h3>
      <div className="users-row">
        {filteredUsersByDate[date].map((user) => (
          <div key={user.id} className="user-card">
            <img src={user.profileImage || userIcon} alt={user.name} className="profile-image" />
            <div className="user-details">
              <h2>{user.name}</h2>
              <p><FontAwesomeIcon icon={faEnvelope} /> {user.email}</p>
              <p><FontAwesomeIcon icon={faCalendar} /> {user.dob}</p>
              <p>
                <FontAwesomeIcon icon={faBriefcase} />{" "}
                {user.designation === "others" ? user.otherDesignation : user.designation}
              </p>
              <p><FontAwesomeIcon icon={faMoneyBillAlt} /> {user.balance}</p>
              <p><FontAwesomeIcon icon={faPhone} /> {user.phoneNumber}</p>
              <p><FontAwesomeIcon icon={faCheckCircle} /> Subscription Status: {user.isSubscribed ? "Subscribed" : "Not Subscribed"}</p>
                      {user.isSubscribed && (
                        <>
                          <p><FontAwesomeIcon icon={faCalendar} /> Start Date: {user.subscriptionStartDate?.toDate().toLocaleDateString()}</p>
                          <p><FontAwesomeIcon icon={faCalendar} /> End Date: {user.subscriptionEndDate?.toDate().toLocaleDateString()}</p>
                        </>
                      )}
            </div>
            <div className="button-container">
              <button
                className="button-edit"
                onClick={() => handleEdit(user)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                className="button-delete"
                onClick={() => handleDelete(user)}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>

      </div>
      {modalState.show && (
        <ConfirmationModal
          show={modalState.show}
          onClose={closeModal}
          onConfirm={confirmDelete}
          message={`Are you sure you want to delete this user?`}
        />
      )}
      {editModalState.show && (
        <EditUserModal
          show={editModalState.show}
          onClose={closeEditModal}
          user={editModalState.user}
          onSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};

const EditUserModal = ({ show, onClose, user, onSubmit }) => {
  const [updatedUser, setUpdatedUser] = useState({ ...user });
  const [isSubscribed, setIsSubscribed] = useState(user.isSubscribed || false);
  const [isOtherDesignationVisible, setIsOtherDesignationVisible] = useState(user.designation === 'Others');

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    // Ensure that balance is being converted correctly on change
    const updatedValue = name === 'balance' ? parseFloat(value) : value;

    setUpdatedUser((prev) => ({ ...prev, [name]: updatedValue }));
  };

  const handleDesignationChange = (e) => {
    const designation = e.target.value;
    setUpdatedUser((prev) => ({ ...prev, designation }));
    setIsOtherDesignationVisible(designation === "Others");
  };

  const handleSubscriptionChange = (e) => {
    setIsSubscribed(e.target.checked);
    setUpdatedUser((prev) => ({ ...prev, isSubscribed: e.target.checked }));
  };

  const handleDateChange = (name, date) => {
    setUpdatedUser((prev) => ({ ...prev, [name]: date }));
  };

  const handleSubmit = () => {
    onSubmit(updatedUser);
  };

  const designationOptions = [
    { label: "Student", value: "Student" },
    { label: "Lawyer", value: "EnrollAsLawyer" },
    // { label: "Auditor", value: "EnrollAsAuditor" },
    { label: "Law Firm", value: "EnrollAsLawFirm" },
    { label: "Consultant", value: "EnrollAsConsultant" },
    { label: "Private Detective", value: "EnrollAsPrivateDetective" },
    { label: "Others", value: "Others" },
  ];


  if (!show) return null;

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <h2>Edit User</h2>
        <div className="edit-modal-content">
          <label>
            Name:
            <input
              type="text"
              name="name"
              value={updatedUser.name}
              onChange={handleChange}
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={updatedUser.email}
              onChange={handleChange}
            />
          </label>
          <label>
            DOB:
            <input
              type="date"
              name="dob"
              value={updatedUser.dob}
              onChange={handleChange}
            />
          </label>
          <label>Designation:</label>
          <select
          style={{
            height: "35px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            padding: "0 10px",
          }}
            name="designation"
            value={updatedUser.designation}
            onChange={handleDesignationChange}
          >
            <option value="" disabled>Select Designation</option>
            {designationOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          {isOtherDesignationVisible && (
            <>
              <label>Other Designation:</label>
              <input
                type="text"
                name="otherDesignation"
                value={updatedUser.otherDesignation || ""}
                onChange={handleChange}
              />
            </>
          )}

          <label>
            Balance:
            <input
              type="number"
              name="balance"
              value={updatedUser.balance}
              onChange={handleChange}
            />
          </label>
          <label>
            Subscription:
            <input
              type="checkbox"
              name="isSubscribed"
              checked={isSubscribed}
              onChange={handleSubscriptionChange}
            />
          </label>
          {isSubscribed && (
            <>
              <label>
                Subscription Start Date:
                <DatePicker
                  selected={updatedUser.subscriptionStartDate?.toDate() || null}
                  onChange={(date) => handleDateChange("subscriptionStartDate", firebase.firestore.Timestamp.fromDate(date))}
                  dateFormat="dd/MM/yyyy"
                />
              </label>
              <label>
                Subscription End Date:
                <DatePicker
                  selected={updatedUser.subscriptionEndDate?.toDate() || null}
                  onChange={(date) => handleDateChange("subscriptionEndDate", firebase.firestore.Timestamp.fromDate(date))}
                  dateFormat="dd/MM/yyyy"
                />
              </label>
            </>
          )}
        </div>
        <div className="edit-modal-buttons">
          <button onClick={handleSubmit} style={{ backgroundColor: '#4CAF50' }}>Save</button>
          <button onClick={onClose} style={{ backgroundColor: "#d9534f" }}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default UsersScreen;
